import './App.css';
import SocialFollow from "./SocialFollow"
import { BrowserRouter,
  Switch,
  Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>

      <div className="App">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/play-store" component={() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.theskate.app';
            return null;
          }}/>
          <Route path="/">
            <SocialFollow />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
