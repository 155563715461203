import React from "react";
import './App.css';
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../src/logo.png";
import downloadOnAppStore from "../src/download-on-app-store.png";


export default function SocialFollow() {
    return (
        <div>

            <img src={logo} style={{width: '75%', marginTop: -80}}/>

            <h3></h3>

            <a href="https://apps.apple.com/us/app/the-skate-app/id1601881680&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img src={downloadOnAppStore} style={{height: 100, marginTop: -20}}/>
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.theskate.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{height: 100}}/>
            </a>

            <h3></h3>

            <a href="https://www.youtube.com/channel/UC_0C2kCsy-U7x1jOfT8QQow" className="youtube social">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>

            <a href="https://www.facebook.com/theskateapp/" className="facebook social">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.twitter.com/theskateapp" className="twitter social">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>

            <a href="https://www.instagram.com/theskate.app" className="instagram social">
                <FontAwesomeIcon icon={faInstagram} size="2x" color="#FFFFFF" />
            </a>

            <h3></h3>

            <a href="https://www.freeprivacypolicy.com/live/99c68fe5-054e-4040-8d03-11380c87c973"
                style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: 14 }}>
                Privacy Policy
            </a>

            <h3></h3>

        </div>
      );
}


